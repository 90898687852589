import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { Fan } from '@/fairplayer/domain/fan/Fan';
import { FanPersonalInfo } from '@/fairplayer/domain/fan/FanPersonalInfo';
import { InjectionKey } from 'vue';
import { Club } from '@/fairplayer/domain/club/Club';
import { FanToCreate } from '@/fairplayer/domain/fan/FanToCreate';
import { FanToUpdate } from '@/fairplayer/domain/fan/FanToUpdate';
import { CardPaymentMethod } from '@/fairplayer/domain/fan/CardPaymentMethod';
import { CheckoutSession } from '@/fairplayer/domain/CheckoutSession';
import { Url } from '@/common/domain/Url';
import { Username } from '@/common/domain/Username';

export const fanRepositoryKey: InjectionKey<FanRepository> = Symbol();

export interface FanRepository {
  registerFan(fanToCreate: FanToCreate): Promise<Fan>;
  getForClub(club: Club, forceRefresh?: boolean): Promise<Fan>;
  update(fanToUpdate: FanToUpdate): Promise<void>;
  updateLanguage(clubSlug: ClubSlug, username: Username): Promise<void>;
  sendPersonalInfoFor(fanInfo: FanPersonalInfo, clubSlug: ClubSlug): Promise<Fan>;
  acceptTos(clubSlug: ClubSlug): Promise<void>;
  setCardPaymentMethodFor(clubSlug: ClubSlug, callbackUrl: Url): Promise<CheckoutSession>;
  getCardPaymentMethodFor(clubSlug: ClubSlug): Promise<CardPaymentMethod>;
}
