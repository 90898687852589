import { ExclusiveUi, fromExclusive } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { defineComponent, inject, onMounted, PropType, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { exclusiveRepositoryKey } from '@/fairplayer/domain/exclusive/ExclusiveRepository';
import { windowScrollerKey } from '@/common/primary/WindowScroller';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { loggerKey } from '@/common/domain/Logger';
import { ExclusivesCarouselVue } from '@/fairplayer/primary/exclusive-carousel';
import { ExclusiveId } from '@/fairplayer/domain/exclusive/ExclusiveId';
import { FanId } from '@/common/domain/FanId';

export default defineComponent({
  name: 'RecommendedExclusives',

  components: { ExclusivesCarouselVue },

  props: {
    currentExclusive: {
      type: Object as PropType<ExclusiveUi>,
      required: true,
    },
    showLike: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    likes: {
      type: Array as PropType<ExclusiveId[]>,
      default: () => [],
    },
    fanId: {
      type: String as PropType<FanId>,
      required: false,
    },
  },

  setup(props) {
    const logger = inject(loggerKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const windowScroller = inject(windowScrollerKey)!;

    const club = clubRepository.getCurrentClub();
    const recommendedExclusives = ref<ExclusiveUi[]>([]);
    const { t } = useI18n();

    watch(
      () => props.currentExclusive,
      (newValue, oldValue) => {
        if (newValue?.id !== oldValue?.id) {
          return loadRecommendedExclusives();
        }
      }
    );

    const loadRecommendedExclusives = async () => {
      return exclusiveRepository
        .listRecommended(club.slug, props.currentExclusive.slug, props.currentExclusive.category)
        .then(list => {
          windowScroller.scrollToTop();
          recommendedExclusives.value = list.map(exclusive =>
            fromExclusive(exclusive, club, props.fanId, props.likes.includes(exclusive.id))
          );
        })
        .catch(error => logger.error('Failed to retrieve recommended exclusives', error));
    };

    onMounted(() => loadRecommendedExclusives());

    return { t, recommendedExclusives };
  },
});
