import { Auction } from '@/fairplayer/domain/exclusive/Auction';
import { AuctionStatus } from '@/fairplayer/domain/exclusive/AuctionStatus';

export interface AuctionUi {
  id: string;
  name: string;
  startDate: Date;
  endDate: Date;
  status: AuctionStatus;
  anonymous: boolean;
}

export const fromAuction = (auction: Auction): AuctionUi => ({
  id: auction.id,
  name: auction.name,
  startDate: auction.startDate,
  endDate: auction.endDate,
  status: auction.status,
  anonymous: auction.anonymous,
});
