import { Optional } from '@/common/domain/Optional';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { Exclusive } from '@/fairplayer/domain/exclusive/Exclusive';
import { ExclusiveCategory } from '@/fairplayer/domain/exclusive/ExclusiveCategory';
import { ExclusiveId } from '@/fairplayer/domain/exclusive/ExclusiveId';
import { ExclusiveRepository } from '@/fairplayer/domain/exclusive/ExclusiveRepository';
import { ExclusiveSlug } from '@/fairplayer/domain/exclusive/ExclusiveSlug';
import { BackendExclusiveRepository } from '@/fairplayer/secondary/exclusive/BackendExclusiveRepository';
import { FiatOrderToCreate } from '@/fairplayer/domain/exclusive/FiatOrderToCreate';
import { Bid } from '@/fairplayer/domain/exclusive/Bid';
import { CheckoutSession } from '@/fairplayer/domain/CheckoutSession';

export class FairplayerExclusiveRepository implements ExclusiveRepository {
  constructor(private backendExclusiveRepository: BackendExclusiveRepository) {}

  listBySlug(slug: ClubSlug): Promise<Exclusive[]> {
    return this.backendExclusiveRepository.listExclusivesBySlug(slug);
  }

  async listRecommended(clubSlug: ClubSlug, exclusiveSlug: ExclusiveSlug, category: ExclusiveCategory): Promise<Exclusive[]> {
    const exclusives = await this.listBySlug(clubSlug);
    return exclusives.filter(exclusive => exclusive.category === category && exclusive.slug !== exclusiveSlug);
  }

  async listDuePayments(clubSlug: ClubSlug): Promise<Exclusive[]> {
    return this.backendExclusiveRepository.listDuePayments(clubSlug);
  }

  getBySlug(clubSlug: ClubSlug, slug: ExclusiveSlug): Promise<Optional<Exclusive>> {
    return this.backendExclusiveRepository.getExclusiveBySlug(clubSlug, slug);
  }

  async createCoinsOrder(slug: ClubSlug, exclusiveId: ExclusiveId): Promise<void> {
    await this.backendExclusiveRepository.createCoinsOrder(slug, exclusiveId);
  }

  async createFiatOrder(order: FiatOrderToCreate): Promise<CheckoutSession> {
    return this.backendExclusiveRepository.createFiatOrder(order);
  }

  async createBid(clubSlug: ClubSlug, exclusiveSlug: ExclusiveSlug, priceCoinsAmount: number): Promise<Bid> {
    return this.backendExclusiveRepository.createBid(clubSlug, exclusiveSlug, priceCoinsAmount);
  }

  like(clubSlug: ClubSlug, exclusiveSlug: ExclusiveSlug): Promise<void> {
    return this.backendExclusiveRepository.like(clubSlug, exclusiveSlug);
  }

  unlike(clubSlug: ClubSlug, exclusiveSlug: ExclusiveSlug): Promise<void> {
    return this.backendExclusiveRepository.unlike(clubSlug, exclusiveSlug);
  }

  listLikes(clubSlug: ClubSlug): Promise<ExclusiveId[]> {
    return this.backendExclusiveRepository.listLikes(clubSlug);
  }
}
