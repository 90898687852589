include ../../../common/primary/icon/icon.mixin.pug

PageVue
  template(v-slot:body)
    .fp-vertical-spacing(v-if='exclusivesWithAuction.isLoading()')
      .fp-vertical-spacing--line
        .fp-placeholder.-height-sm
      .fp-vertical-spacing--line
        .fp-responsive-grid.-cols3.-cols2-up-md
          .fp-responsive-grid--column(v-for='(_, index) in 6')
            .fp-placeholder.-height-xl
    .fp-vertical-spacing.-s-xl(v-if='!exclusivesWithAuction.isLoading()')
      .fp-vertical-spacing--line(v-if='!exclusivesWithAuction.value().length' data-test='empty')
        .fp-vertical-spacing
          .fp-vertical-spacing--line.-align-center
            +fp-icon('info', {color: 'shade-300', size: '50'})
          .fp-vertical-spacing--line.-align-center
            h2.fp-title {{ t('auctionsPage.noAuctions') }}
          .fp-vertical-spacing--line.-align-center
            .fp-text {{ t('auctionsPage.comebackLater') }}
      LostAuctionsVue(:exclusives='exclusivesWithAuction.value()')
      .fp-vertical-spacing--line(v-for='auction in activeAuctionsWithExclusives' :key='auction.id' data-test='active-auctions')
        .fp-vertical-spacing
          .fp-vertical-spacing--line
            .fp-icon-with-text
              .fp-dot
              .fp-icon-with-text--text
                .fp-icon-with-text--text-line
                  h2.fp-title(data-test='name') {{ auction.name }}
                .fp-icon-with-text--text-line
                  | {{ t('auctionsPage.ends') }}&nbsp;
                  RelativeTimeVue(:date='auction.endDate')
          .fp-vertical-spacing--line
            .fp-responsive-grid.-cols4.-cols3-up-lg.-cols2-up-md.-s-sm-up-sm
              .fp-responsive-grid--column(v-for='(exclusive, index) in exclusivesFor(auction.id)' :key='index' data-test='active-exclusives')
                router-link.fp-link.-no-text-decoration(:to='{ name: \'exclusiveDetail\', params: { exclusiveSlug: exclusive.slug } }' :id='exclusive.slug')
                  PresentationCardVue(:exclusive='exclusive' :data-test='`exclusive-${index}`' :show-like='isAuthenticated.value()')
      .fp-vertical-spacing--line(v-for='auction in upcomingAuctionsWithExclusives' :key='auction.id' data-test='upcoming-auctions')
        .fp-vertical-spacing
          .fp-vertical-spacing--line
            .fp-icon-with-text
              +fp-icon('calendar', {size: '24'})
              .fp-icon-with-text--text
                .fp-icon-with-text--text-line
                  h2.fp-title(data-test='name') {{ auction.name }}
                .fp-icon-with-text--text-line
                  | {{ t('auctionsPage.starts') }}&nbsp;
                  RelativeTimeVue(:date='auction.startDate')
          .fp-vertical-spacing--line
            .fp-responsive-grid.-cols4.-cols3-up-lg.-cols2-up-md.-s-sm-up-sm
              .fp-responsive-grid--column(v-for='(exclusive, index) in exclusivesFor(auction.id)' :key='index' data-test='upcoming-exclusives')
                router-link.fp-link.-no-text-decoration(:to='{ name: \'exclusiveDetail\', params: { exclusiveSlug: exclusive.slug } }' :id='exclusive.slug')
                  PresentationCardVue(:exclusive='exclusive' :data-test='`exclusive-${index}`' :show-like='isAuthenticated.value()')
      .fp-vertical-spacing--line(v-if='closedAuctionsWithExclusives.length > 0')
        .fp-vertical-spacing
          .fp-vertical-spacing--line
            h2.fp-title {{ t('auctionsPage.closedAuctions') }}
          .fp-vertical-spacing--line(v-for='auction in closedAuctionsWithExclusives' :key='auction.id' data-test='closed-auctions')
            .fp-vertical-spacing
              .fp-vertical-spacing--line
                .fp-icon-with-text
                  .fp-icon-with-text--text
                    .fp-icon-with-text--text-line
                      h3.fp-title(data-test='name') {{ auction.name }}
                    .fp-icon-with-text--text-line
                      | {{ t('auctionsPage.ended') }}&nbsp;
                      RelativeTimeVue(:date='auction.endDate')
              .fp-vertical-spacing--line
                .fp-responsive-grid.-cols4.-cols3-up-lg.-cols2-up-md.-s-sm-up-sm
                  .fp-responsive-grid--column(v-for='(exclusive, index) in exclusivesFor(auction.id)' :key='index' data-test='closed-exclusives')
                    router-link.fp-link.-no-text-decoration(:to='{ name: \'exclusiveDetail\', params: { exclusiveSlug: exclusive.slug } }' :id='exclusive.slug')
                      PresentationCardVue(:exclusive='exclusive' :data-test='`exclusive-${index}`' :show-like='isAuthenticated.value()')
  template(v-slot:footer v-if='!auctions.isLoading() && !exclusivesWithAuction.isLoading() && activeAuctionsWithExclusives.length')
    LiveAuctionFooterVue(:auction='activeAuctionsWithExclusives[0]')
