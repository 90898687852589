import { computed, inject, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { PageVue } from '@/fairplayer/primary/page';
import { loggerKey } from '@/common/domain/Logger';
import { Loader } from '@/loader/primary/Loader';
import { ExclusiveUi, fromExclusive } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { exclusiveRepositoryKey } from '@/fairplayer/domain/exclusive/ExclusiveRepository';
import { PresentationCardVue } from '@/fairplayer/primary/presentation-card';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { ExclusiveId } from '@/fairplayer/domain/exclusive/ExclusiveId';
import { auctionRepositoryKey } from '@/fairplayer/domain/exclusive/AuctionRepository';
import { AuctionUi, fromAuction } from '@/fairplayer/primary/marketplace/Auction.ui';
import { RelativeTimeVue } from '@/common/primary/relative-time';
import { AuctionStatus } from '@/fairplayer/domain/exclusive/AuctionStatus';
import { LostAuctionsVue } from '@/fairplayer/primary/auctions-page/lost-auctions';
import { LiveAuctionFooterVue } from '@/fairplayer/primary/auctions-page/live-auction-footer';
import { globalWindowKey } from '@/common/domain/Window';
import { windowScrollerKey } from '@/common/primary/WindowScroller';
import { useRouter } from 'vue-router';

export default {
  name: 'AuctionsPage',

  components: {
    LostAuctionsVue,
    PageVue,
    PresentationCardVue,
    RelativeTimeVue,
    LiveAuctionFooterVue,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();

    const auctionRepository = inject(auctionRepositoryKey)!;
    const authentication = inject(authenticationKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const logger = inject(loggerKey)!;
    const globalWindow = inject(globalWindowKey)!;
    const windowScroller = inject(windowScrollerKey)!;

    const auctions = ref(Loader.loading<AuctionUi[]>());
    const exclusivesWithAuction = ref(Loader.loading<ExclusiveUi[]>());
    const isAuthenticated = ref(Loader.loading<boolean>());
    const refreshExclusivesIntervalId = ref(0);

    const withExclusives = (status: AuctionStatus) =>
      computed(() => auctions.value.value().filter(auction => auction.status === status && exclusivesFor(auction.id).length));

    const upcomingAuctionsWithExclusives = withExclusives(AuctionStatus.UPCOMING);
    const activeAuctionsWithExclusives = withExclusives(AuctionStatus.ACTIVE);
    const closedAuctionsWithExclusives = withExclusives(AuctionStatus.CLOSED);

    const sortByNumberThenStartingPriceDescThenName = (e1: ExclusiveUi, e2: ExclusiveUi) => {
      const difNumber = (e1.auctionLot!.number || 0) - (e2.auctionLot!.number || 0);
      if (difNumber !== 0) {
        return difNumber;
      }

      const difPrice = e2.auctionLot!.startingPrice.totalCost.value - e1.auctionLot!.startingPrice.totalCost.value;
      if (difPrice !== 0) {
        return difPrice;
      }

      return e1.name.localeCompare(e2.name);
    };

    const loadAuctions = async (): Promise<void> => {
      try {
        const auctionsRetrieved = await auctionRepository.listByClub(clubRepository.getCurrentSlug());
        auctions.value.loaded(auctionsRetrieved.map(fromAuction));
      } catch (error: any) {
        logger.error('Failed to retrieve auctions', error);
        auctions.value.loaded([]);
      }
    };

    const loadExclusives = async (): Promise<void> => {
      try {
        let fanId = undefined;
        let likes: ExclusiveId[] = [];
        if (isAuthenticated.value.value()) {
          const fan = await fanRepository.getForClub(clubRepository.getCurrentClub());
          fanId = fan.id;
          likes = await exclusiveRepository.listLikes(clubRepository.getCurrentSlug());
        }
        const exclusivesResponse = await exclusiveRepository.listBySlug(clubRepository.getCurrentSlug());
        const mappedExclusives = exclusivesResponse
          .filter(exclusive => exclusive.auctionLot.isPresent())
          .map(exclusive => fromExclusive(exclusive, clubRepository.getCurrentClub(), fanId, likes.includes(exclusive.id)))
          .sort(sortByNumberThenStartingPriceDescThenName);
        exclusivesWithAuction.value.loaded(mappedExclusives);
        autoRefreshExclusives(mappedExclusives);
      } catch (error: any) {
        logger.error('Failed to retrieve exclusives with auction', error);
        exclusivesWithAuction.value.loaded([]);
      }
    };

    const exclusivesFor = (auctionId: string) => exclusivesWithAuction.value.value().filter(e => e.auctionLot!.auction.id === auctionId);

    const autoRefreshExclusives = (exclusives: ExclusiveUi[]) => {
      if (refreshExclusivesIntervalId.value || exclusives.length === 0) {
        return;
      }
      refreshExclusivesIntervalId.value = globalWindow.setInterval(loadExclusives, 10000);
    };

    onMounted(async () => {
      await authentication.isAuthenticated().then(authenticated => {
        isAuthenticated.value.loaded(authenticated);
      });

      await loadAuctions();
      await loadExclusives();

      if (router.currentRoute.value.hash) {
        windowScroller.scrollToId(router.currentRoute.value.hash.substring(1));
      }
    });

    onUnmounted(() => {
      if (refreshExclusivesIntervalId.value) {
        globalWindow.clearInterval(refreshExclusivesIntervalId.value);
      }
    });

    return {
      exclusivesFor,
      closedAuctionsWithExclusives,
      activeAuctionsWithExclusives,
      upcomingAuctionsWithExclusives,
      auctions,
      exclusivesWithAuction,
      isAuthenticated,
      t,
    };
  },
};
