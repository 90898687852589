include ../../../../common/primary/icon/icon.mixin.pug

.fp-vertical-spacing--line(v-if='lostAuctions.length' data-test='lost-auctions')
  .fp-alert.-warning
    .fp-vertical-spacing
      .fp-vertical-spacing--line
        .fp-icon-with-text
          +fp-icon('close-circle', {color: 'warning', size: '18'})
          span.fp-text.-text-sm {{ t('auctionsPage.lostAuctions') }}
      .fp-vertical-spacing--line(v-for='lostAuction in lostAuctions')
        router-link.fp-link.-no-text-decoration(:to='{ name: \'exclusiveDetail\', params: { exclusiveSlug: lostAuction.slug }}')
          .fp-horizontal-spacing.-s-sm.-items-center
            .fp-horizontal-spacing--column
              .fp-image.-md.-rounded
                FairplayerImageVue(:url='lostAuction.imageUrl' highlightable data-test='exclusive-image')
            .fp-horizontal-spacing--column.-expand
              .fp-text.-text-semi-bold(data-test='exclusive-name') {{ lostAuction.name }}
