import { defineComponent, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { fanLanguageRepositoryKey } from '@/common/domain/FanLanguageRepository';
import { Language } from '@/common/domain/Language';
import { globalWindowKey } from '@/common/domain/Window';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { authenticationKey } from '@/common/domain/auth/Authentication';

export default defineComponent({
  name: 'LanguageSelector',

  setup() {
    const { t } = useI18n();

    const clubRepository = inject(clubRepositoryKey)!;
    const authentication = inject(authenticationKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const fanLanguageRepository = inject(fanLanguageRepositoryKey)!;
    const globalWindow = inject(globalWindowKey)!;

    const club = clubRepository.getCurrentClub();

    const clubLanguages = fromClub(club).languages;

    const setLanguage = async (language: Language) => {
      fanLanguageRepository.storeLanguage(language);

      const authenticated = await authentication.isAuthenticated();
      if (authenticated) {
        const authenticatedFan = await authentication.authenticatedFan();

        await fanRepository.updateLanguage(club.slug, authenticatedFan.username);
      }

      globalWindow.location.reload();
    };

    return {
      clubLanguages,
      setLanguage,
      t,
    };
  },
});
