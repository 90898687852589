import { defineAsyncComponent, markRaw } from 'vue';

const importAsyncModalComponent = (component: any) => markRaw(defineAsyncComponent(component));

export const exclusivesListFiltersBottomModal = () =>
  importAsyncModalComponent(
    () => import('@/fairplayer/primary/marketplace/exclusives-list/exclusives-list-filter-modal/ExclusivesListFilterModal.vue')
  );

export const confirmPurchaseBottomModal = () =>
  importAsyncModalComponent(
    () => import('@/fairplayer/primary/marketplace/exclusive-page/confirm-purchase-modal/ConfirmPurchaseModal.vue')
  );

export const profilePictureBottomModal = () =>
  importAsyncModalComponent(() => import('@/fairplayer/primary/profile/profile-picture-modal/ProfilePictureModal.vue'));

export const bookingSlotConfirmationBottomModal = () =>
  importAsyncModalComponent(
    () =>
      import(
        '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/booking-slots/booking-slot-confirmation-modal/BookingSlotConfirmationModal.vue'
      )
  );

export const addBidModal = () =>
  importAsyncModalComponent(() => import('@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/add-bid-modal/AddBidModal.vue'));

export const setupCardPaymentMethodModal = () =>
  importAsyncModalComponent(
    () => import('@/fairplayer/primary/marketplace/exclusive-page/setup-card-payment-method/SetupCardPaymentMethodModal.vue')
  );
