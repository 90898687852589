import { computed, defineComponent, inject, PropType, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { useRouter } from 'vue-router';
import { FairplayerButtonVue } from '@/common/primary/button';
import { GiveawayCardVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/giveaway-card';
import { ExclusiveDetailHeadingVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/exclusive-detail-heading';
import { highlightedMediaBusKey } from '@/common/domain/highlighted-media/HighlightedMediaBus';
import { fromMedia } from '@/fairplayer/primary/marketplace/exclusives-list/Media.ui';
import { MediaType } from '@/common/domain/MediaType';
import { ExclusiveMediaVue } from '@/common/primary/exclusive-media';
import { ExclusiveAuctionVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/exclusive-auction';
import { FairplayerVideoVue } from '@/common/primary/fairplayer-video';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';
import { BookingSlotsVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/booking-slots';
import { ExclusiveRepository, exclusiveRepositoryKey } from '@/fairplayer/domain/exclusive/ExclusiveRepository';

export default defineComponent({
  name: 'ExclusiveDetail',

  components: {
    BookingSlotsVue,
    FairplayerButtonVue,
    GiveawayCardVue,
    ExclusiveAuctionVue,
    ExclusiveDetailHeadingVue,
    ExclusiveMediaVue,
    FairplayerVideoVue,
  },

  props: {
    exclusive: {
      type: Object as PropType<ExclusiveUi>,
      required: true,
    },
    showLike: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },

  emits: ['add-bid'],

  setup(props, { emit }) {
    const { d, t } = useI18n();
    const router = useRouter();
    const clubRepository = inject(clubRepositoryKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const highlightedMediaBus = inject(highlightedMediaBusKey)!;

    const club = fromClub(clubRepository.getCurrentClub()!);

    const liked = ref<boolean | undefined>();
    const selectedMedia = ref(0);

    const localLiked = computed(() => {
      if (liked.value !== undefined) {
        return liked.value;
      }
      return props.exclusive.isLiked;
    });
    const medias = computed(() => [{ url: props.exclusive.imageUrl, type: MediaType.IMAGE }, ...props.exclusive.medias.map(fromMedia)]);

    watch(
      () => props.exclusive,
      (newValue, oldValue) => {
        if (newValue.id !== oldValue.id) {
          selectedMedia.value = 0;
        }
      }
    );

    const goBack = async () => {
      if (props.exclusive.auctionLot !== undefined) {
        await goToAuctionsPage();
      } else if (club.isFoundation) {
        await goToDonationPage();
      } else {
        await goToExclusiveList();
      }
    };
    const goToExclusiveList = async () => await router.push({ name: 'marketplace' });
    const goToAuctionsPage = async () => await router.push({ name: 'auctionsPage' });
    const goToDonationPage = async () => await router.push({ name: 'donationPage' });

    const selectMedia = (index: number) => {
      selectedMedia.value = index;
    };

    const addBid = () => emit('add-bid');

    const highlightMedia = () => {
      highlightedMediaBus.open({
        medias: medias.value,
        activeIndex: selectedMedia.value,
      });
    };

    const toggleLike = (e: Event, isLiked: boolean, applyLike: keyof Pick<ExclusiveRepository, 'like' | 'unlike'>) => {
      e.preventDefault();
      liked.value = isLiked;

      exclusiveRepository[applyLike](clubRepository.getCurrentSlug(), props.exclusive.slug).catch(() => {
        liked.value = !isLiked;
      });
    };

    const like = (e: Event) => toggleLike(e, true, 'like');
    const unlike = (e: Event) => toggleLike(e, false, 'unlike');

    return {
      d,
      t,
      localLiked,
      like,
      unlike,
      club,
      addBid,
      selectedMedia,
      medias,
      selectMedia,
      goBack,
      highlightMedia,
    };
  },
});
