import { authenticationKey } from '@/common/domain/auth/Authentication';
import { AnnouncementVue } from '@/common/primary/announcement';
import {
  AnnouncementUi,
  createArchivedClubAnnouncementUi,
  createDevEnvironmentAnnouncementUi,
  createPaymentsDueAnnouncementUi,
} from '@/common/primary/announcement/Announcement.ui';
import { HorizontalMenuVue } from '@/common/primary/horizontal-menu';
import { MenuItemUi } from '@/common/primary/MenuItem.ui';
import { NotFoundVue } from '@/common/primary/not-found';
import { clubNotFoundUi } from '@/common/primary/not-found/NotFound.ui';
import { TopMenuVue } from '@/common/primary/top-menu';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { Fan } from '@/fairplayer/domain/fan/Fan';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { ModalVue } from '@/fairplayer/primary/club-layout/modal';
import { ClubUi, fromClub } from '@/fairplayer/primary/club/Club.ui';
import { ToastsListVue } from '@/fairplayer/primary/toasts/toasts-list';
import { Loader } from '@/loader/primary/Loader';
import { computed, defineComponent, inject, onMounted, onUnmounted, Ref, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Club } from '@/fairplayer/domain/club/Club';
import { PrinterVue } from '@/fairplayer/primary/club-layout/printer';
import { PageVue } from '@/fairplayer/primary/page';
import { HighlightedMediaVue } from '@/fairplayer/primary/club-layout/highlighted-media';
import { BottomModalVue } from '@/fairplayer/primary/club-layout/bottom-modal';
import { loggerKey } from '@/common/domain/Logger';
import { ClubState } from '@/fairplayer/domain/club/ClubState';
import { createMenuItems } from '@/fairplayer/primary/club-layout/menu/MenuItems.ui';

import { CLUB_HOMEPAGE_ROUTE_NAME } from '@/router/routes';
import { KycStatus } from '@/fairplayer/domain/fan/KycStatus';
import { themeRepositoryKey } from '@/fairplayer/domain/theme/ThemeRepository';
import { globalWindowKey } from '@/common/domain/Window';
import { exclusiveRepositoryKey } from '@/fairplayer/domain/exclusive/ExclusiveRepository';
import { ClubModel } from '@/fairplayer/domain/club/ClubModel';

export default defineComponent({
  name: 'ClubLayout',

  components: {
    AnnouncementVue,
    BottomModalVue,
    HighlightedMediaVue,
    HorizontalMenuVue,
    NotFoundVue,
    PageVue,
    PrinterVue,
    ToastsListVue,
    TopMenuVue,
    ModalVue,
  },

  setup() {
    const authentication = inject(authenticationKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const logger = inject(loggerKey)!;
    const themeRepository = inject(themeRepositoryKey)!;
    const globalWindow = inject(globalWindowKey)!;

    const router = useRouter();
    const route = useRoute();

    const pageReady = ref(false);
    const paymentsDueAnnouncementUi: Ref<AnnouncementUi | undefined> = ref(undefined);
    const displayDevEnvironmentAnnouncement = ref(true);
    const displayClubError = ref<boolean>(false);
    const fan = ref<Fan>();
    const clubSlug = ref('');
    const club = ref(Loader.loading<ClubUi | null>());
    const isAuthenticated = ref(Loader.loading<boolean>());
    const isArchived = ref(false);
    const isDevEnvironment = import.meta.env.VITE_ENV_SLUG === 'dev';
    const menuItems = ref<MenuItemUi[]>([]);
    const refreshDuePaymentsIntervalId = ref(0);

    const authenticationRequired = computed(
      () => club.value.value() && club.value.value()!.referralNeeded && !isAuthenticated.value.value()
    );

    onMounted(async () => {
      clubRepository
        .retrieveClub()
        .then(onClubRetrieved)
        .catch(() => clubLayoutError());
    });

    const onClubRetrieved = async (clubRetrieved: Club) => {
      clubSlug.value = clubRetrieved.slug;
      clubRepository.setCurrentClub(clubRetrieved);
      const clubUi = fromClub(clubRetrieved);
      club.value.loaded(clubUi);
      isArchived.value = clubRetrieved.state === ClubState.ARCHIVED;

      initDocTitle();
      initDocFavicon();
      initTheme();
      initThemeVariant();

      await retrieveAuthentication();

      menuItems.value = createMenuItems(clubUi, isAuthenticated.value.value(), fan.value?.kycStatus === KycStatus.VALIDATED);
      pageReady.value = true;
    };

    const clubLayoutError = () => {
      club.value.loaded(null);
      displayClubError.value = true;
      clubRepository.clearSelectedClub();
      pageReady.value = true;
    };

    const initDocTitle = () => {
      globalWindow.document.title = club.value.value()!.platformName;
    };

    const initDocFavicon = () => globalWindow.document.getElementById('favicon')?.setAttribute('href', club.value.value()!.logoUrl);

    const initTheme = () =>
      themeRepository.get().ifPresentOrElse(
        theme => themeRepository.set(theme),
        () => {
          if (club.value.value()!.referralNeeded) {
            themeRepository.set('dark');
          }
        }
      );

    const initThemeVariant = () => {
      if (club.value.value()!.vip) {
        globalWindow.document.getElementsByTagName('html')[0].setAttribute('data-theme-variant', 'psg');
      }
    };

    const retrieveFan = async () => {
      await fanRepository
        .getForClub(clubRepository.getCurrentClub())
        .then((retrievedFan: Fan) => (fan.value = retrievedFan))
        .catch((error: any) => {
          logger.error('Failed to retrieve fan', error);
        });
    };

    const retrieveAuthentication = async () => authentication.isAuthenticated().then(onAuthenticationRetrieved);

    const onAuthenticationRetrieved = async (authenticationRetrieved: boolean) => {
      isAuthenticated.value.loaded(authenticationRetrieved);

      if (isAuthenticated.value.value()) {
        await onAuthenticated();
      } else {
        await redirectIfAuthRequired();
      }
    };

    const onAuthenticated = async () => {
      await retrieveFan();

      if (fan.value && fan.value.kycStatus !== KycStatus.VALIDATED && fan.value.kycStatus !== KycStatus.NOT_REFERRED) {
        await router.push({ name: 'kyc' });
      }

      if (club.value.value()!.model === ClubModel.FOUNDATION && fan.value?.kycStatus === KycStatus.VALIDATED) {
        retrieveDuePayments();
      }
    };

    const redirectIfAuthRequired = async () => {
      if (route.name === CLUB_HOMEPAGE_ROUTE_NAME) {
        return;
      }

      if (club.value.value()!.referralNeeded || route.matched.some(record => record.meta.requireAuthentication)) {
        await router.push({ name: CLUB_HOMEPAGE_ROUTE_NAME });
      }
    };

    const retrieveDuePayments = () => {
      exclusiveRepository.listDuePayments(clubSlug.value).then(exclusives => {
        if (exclusives.length) {
          paymentsDueAnnouncementUi.value = createPaymentsDueAnnouncementUi(exclusives.length, exclusives[0].slug);
        }
      });
      if (!refreshDuePaymentsIntervalId.value) {
        refreshDuePaymentsIntervalId.value = globalWindow.setInterval(retrieveDuePayments, 10000);
      }
    };

    const hideDevAnnouncement = () => {
      displayDevEnvironmentAnnouncement.value = false;
    };

    onUnmounted(() => {
      if (refreshDuePaymentsIntervalId.value) {
        globalWindow.clearInterval(refreshDuePaymentsIntervalId.value);
      }
    });

    return {
      pageReady,
      authenticationRequired,
      club,
      clubNotFoundUi,
      displayClubError,
      fan,
      archivedAnnouncementUi: createArchivedClubAnnouncementUi(),
      devEnvironmentAnnouncementUi: createDevEnvironmentAnnouncementUi(),
      paymentsDueAnnouncementUi,
      displayDevEnvironmentAnnouncement,
      isAuthenticated,
      isArchived,
      isDevEnvironment,
      menuItems,
      hideDevAnnouncement,
    };
  },
});
