import { BackendCaller } from '@/common/secondary/BackendCaller';
import { BackendFan } from '@/fairplayer/secondary/fan/BackendFan';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { FanPersonalInfo } from '@/fairplayer/domain/fan/FanPersonalInfo';
import { fromFanPersonalInfo } from '@/fairplayer/secondary/fan/BackendFanPersonalInfo';
import { FanToCreate } from '@/fairplayer/domain/fan/FanToCreate';
import { fromFanToCreate } from '@/fairplayer/secondary/fan/BackendFanToCreate';
import { Username } from '@/common/domain/Username';
import { toBackendFanToUpdate } from '@/fairplayer/secondary/fan/BackendFanToUpdate';
import { FanToUpdate } from '@/fairplayer/domain/fan/FanToUpdate';
import { Optional } from '@/common/domain/Optional';
import { BackendCardPaymentMethod } from '@/fairplayer/secondary/fan/BackendCardPaymentMethod';
import { BackendCheckoutSession } from '@/fairplayer/secondary/BackendCheckoutSession';
import { Url } from '@/common/domain/Url';

export class BackendFanRepository {
  private storedFan: BackendFan | undefined;

  constructor(private backendCaller: BackendCaller) {}

  async registerFan(fanToCreate: FanToCreate): Promise<BackendFan> {
    return this.backendCaller
      .post<BackendFan>(`api/clubs/${fanToCreate.clubSlug}/fans`, fromFanToCreate(fanToCreate))
      .then(fan => fan.data);
  }

  async getFan(clubSlug: ClubSlug, forceRefresh = false): Promise<BackendFan> {
    if (this.storedFan && !forceRefresh) {
      return this.storedFan;
    }
    const fan = await this.callGetFan(clubSlug)
      .then(backendFan => backendFan.data)
      .catch(async () => {
        await this.registerFan({ clubSlug, pictureUrl: Optional.empty() });
        return (await this.callGetFan(clubSlug)).data;
      });

    this.storedFan = fan;

    return fan;
  }

  private callGetFan(clubSlug: ClubSlug) {
    return this.backendCaller.get<BackendFan>(`api/clubs/${clubSlug}/fans/me`);
  }

  async update(fanToUpdate: FanToUpdate): Promise<void> {
    await this.backendCaller.patch<void>(
      `api/clubs/${fanToUpdate.clubSlug}/fans/${encodeURIComponent(fanToUpdate.username)}`,
      toBackendFanToUpdate(fanToUpdate)
    );
  }

  async updateLanguage(clubSlug: ClubSlug, username: Username): Promise<void> {
    await this.backendCaller.put<void>(`api/clubs/${clubSlug}/fans/${encodeURIComponent(username)}/language`);
  }

  async sendPersonalFanInfoFor(fanPersonalInfo: FanPersonalInfo, clubSlug: ClubSlug, fanUsername: Username): Promise<BackendFan> {
    return this.backendCaller
      .put<BackendFan>(`api/clubs/${clubSlug}/fans/${encodeURIComponent(fanUsername)}/personal-info`, fromFanPersonalInfo(fanPersonalInfo))
      .then(fan => fan.data);
  }

  async acceptTos(clubSlug: ClubSlug): Promise<void> {
    await this.backendCaller.post<void>(`api/clubs/${clubSlug}/fans/me/tos-acceptances`);
  }

  async setCardPaymentMethodFor(clubSlug: ClubSlug, username: Username, currentLocation: Url): Promise<BackendCheckoutSession> {
    return this.backendCaller
      .put<BackendCheckoutSession>(`api/clubs/${clubSlug}/fans/${encodeURIComponent(username)}/card-payment-method`, {
        successUrl: `${currentLocation}?payment-result=SUCCESS`,
        cancelUrl: `${currentLocation}?payment-result=FAILURE`,
      })
      .then(checkoutSession => checkoutSession.data);
  }

  async getCardPaymentMethodFor(clubSlug: ClubSlug, username: Username): Promise<BackendCardPaymentMethod> {
    return this.backendCaller
      .get<BackendCardPaymentMethod>(`api/clubs/${clubSlug}/fans/${encodeURIComponent(username)}/card-payment-method`)
      .then(cardPaymentMethod => cardPaymentMethod.data);
  }
}
