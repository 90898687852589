import { InjectionKey } from 'vue';

export const windowScrollerKey: InjectionKey<WindowScroller> = Symbol();

export class WindowScroller {
  constructor(private window: Window) {}

  scrollToId(id: string): void {
    this.window.document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  }

  scrollToTop(): void {
    this.window.document.getElementById('page-wrapper')?.scrollIntoView({ behavior: 'smooth' });
  }
}
