include ../../../common/primary/icon/icon.mixin.pug

form.fp-vertical-spacing(@submit.prevent='createDonation' data-test='create-donation-form')
  .fp-vertical-spacing--line
    h2.fp-title {{ t('oneTimeDonation.contribute') }}
  .fp-vertical-spacing--line
    .fp-card
      .fp-vertical-spacing
        .fp-vertical-spacing--line
          .fp-text.-paragraph {{ t('oneTimeDonation.support') }}
        .fp-vertical-spacing--line
          label.fp-field
            | {{ t('oneTimeDonation.amountDescription') }}
            .fp-icons-input
              label.fp-icons-input--right-icon(for='donation-amount')
                i.fp-icon.fp-glyph-euro.-s16
              input.fp-input-text#donation-amount(type='number' min='1' :placeholder=`t('oneTimeDonation.amount')` v-model='amount' required data-test='amount')
        .fp-vertical-spacing--line
          FairplayerButtonVue(icon='hand-heart' expand type='submit' :loading='isCreatingOneTimeDonation' :text='t("oneTimeDonation.makeDonation")' data-test='create-donation')
        .fp-vertical-spacing--line(v-if='paymentFailed' data-test='payment-failed')
          .fp-alert.-danger
            +fp-icon('close-circle', {color: 'danger', size: '18'})
            span.fp-text.-text-sm {{ t('oneTimeDonation.paymentFailed') }}
