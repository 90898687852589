import { useI18n } from 'vue-i18n';
import { computed, defineComponent, inject, onMounted, Ref, ref } from 'vue';
import { FairplayerButtonVue } from '@/common/primary/button';
import { useRoute, useRouter } from 'vue-router';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { donationRepositoryKey } from '@/fairplayer/domain/donation/DonationRepository';
import { pageRedirecterKey } from '@/common/primary/PageRedirecter';
import { globalWindowKey } from '@/common/domain/Window';
import { loggerKey } from '@/common/domain/Logger';
import { Loader } from '@/loader/primary/Loader';
import { euro } from '@/common/domain/token/Fiat';

export default defineComponent({
  name: 'DonationForm',

  components: {
    FairplayerButtonVue,
  },

  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const authentication = inject(authenticationKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const donationRepository = inject(donationRepositoryKey)!;
    const pageRedirecter = inject(pageRedirecterKey)!;
    const globalWindow = inject(globalWindowKey)!;
    const logger = inject(loggerKey)!;

    const amount: Ref<number | undefined> = ref();
    const isCreatingOneTimeDonation = ref(false);

    const isAuthenticated = ref(Loader.loading<boolean>());

    const paymentFailed = computed(() => route.query['payment-result'] === 'FAILURE');

    const createDonation = async () => {
      if (!isAuthenticated.value.value()) {
        return router.push({ name: 'login' });
      }

      isCreatingOneTimeDonation.value = true;
      const clubSlug = clubRepository.getCurrentSlug();
      const currentLocation = globalWindow.location.href.split('?')[0];
      donationRepository
        .create({ clubSlug, amount: euro(+amount.value!), currentLocation })
        .then(({ url }) => pageRedirecter.navigateTo(url))
        .catch((error: any) => {
          logger.error('Failed to create donation', error);
          isCreatingOneTimeDonation.value = false;
        });
    };

    onMounted(async () => {
      await authentication.isAuthenticated().then(authenticated => {
        isAuthenticated.value.loaded(authenticated);
      });
    });

    return {
      paymentFailed,
      amount,
      createDonation,
      isAuthenticated,
      isCreatingOneTimeDonation,
      t,
    };
  },
});
