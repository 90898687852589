import { inject, onMounted } from 'vue';
import { fanConsentRepositoryKey } from '@/fairplayer/domain/fan/consent/FanConsentRepository';

export default {
  name: 'App',

  setup() {
    const fairplayerFanConsentRepository = inject(fanConsentRepositoryKey)!;

    onMounted(() => {
      fairplayerFanConsentRepository.init();
    });
  },
};
