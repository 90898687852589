form.fp-vertical-spacing#reset-password-form(@submit.prevent='confirmResetPassword' data-test='reset-password-form')
  .fp-vertical-spacing--line.-align-center
    h1.fp-title(data-test='title') {{ t('login.forgotPassword') }}
  .fp-vertical-spacing--line.-justify-center
    .fp-icons-input
      label.fp-icons-input--left-icon(for='username')
        i.fp-icon.fp-glyph-mail.-color-shade-500.-s16
      input.fp-input-text#username(:value='email' type='email' disabled)
  NewPasswordVue(@updated='updateNewPassword')
  .fp-vertical-spacing--line.-justify-center
    .fp-icons-input
      label.fp-icons-input--left-icon(for='confirmation-code')
        i.fp-icon.fp-glyph-check-circle.-color-shade-500.-s16
      input.fp-input-text#confirmation-code(v-model='confirmationCode' type='text' :placeholder=`t('login.confirmationCode')` autocomplete='one-time-code' data-test='confirmation-code' required)
  .fp-vertical-spacing--line.-align-center(v-if='invalidResetPassword')
    span.fp-text.-color-danger(data-test='invalid-reset-password') {{ t('login.invalidResetPassword') }}
  .fp-vertical-spacing--line
    FairplayerButtonVue(icon='check' :text=`t('login.resetPassword')` type='submit' form='reset-password-form' :disabled='disabledResetPassword' :loading='isResettingPassword' expand data-test='confirm-reset-password')
