import { BackendBid, toBid } from '@/fairplayer/secondary/exclusive/BackendBid';
import { BackendPrice, toTokens } from '@/common/secondary/token/BackendPrice';
import { AuctionLot } from '@/fairplayer/domain/exclusive/AuctionLot';
import { BackendAuction, toAuction } from '@/fairplayer/secondary/exclusive/BackendAuction';
import { Optional } from '@/common/domain/Optional';

export interface BackendAuctionLot {
  startingPrice: BackendPrice;
  currentPrice: BackendPrice;
  auction: BackendAuction;
  number?: number;
  bids: BackendBid[];
}

export const toAuctionLot = (backendAuction: BackendAuctionLot): AuctionLot => ({
  startingPrice: toTokens(backendAuction.startingPrice),
  currentPrice: toTokens(backendAuction.currentPrice),
  auction: toAuction(backendAuction.auction),
  number: Optional.ofUndefinable(backendAuction.number),
  bids: backendAuction.bids.map(toBid),
});
