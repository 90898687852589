include ../../../../../common/primary/icon/icon.mixin.pug

.fp-vertical-spacing.-s-sm
  .fp-vertical-spacing--line
    .fp-icon-with-text
      +fp-icon('calendar', {size: '24'})
      .fp-icon-with-text--text
        .fp-icon-with-text--text-line
          h3.fp-title(data-test='name') {{ auction.name }}
        .fp-icon-with-text--text-line
          | {{ t('auctionsPage.starts') }}&nbsp;
          RelativeTimeVue(:date='auction.startDate')
  .fp-vertical-spacing--line
    ExclusivesCarouselVue(:exclusives='exclusives' :show-like='showLike')
