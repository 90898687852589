include ../../../common/primary/icon/icon.mixin.pug

PageVue
  template(v-slot:body)
    .fp-vertical-spacing.-s-xl
      .fp-vertical-spacing--line.-justify-center
        ClubLogoVue
      .fp-vertical-spacing--line(data-test='donation-page')
        .fp-sticky-pane(v-if='exclusives.isLoading()')
          .fp-sticky-pane--content
            .fp-vertical-spacing
              .fp-vertical-spacing--line
                .fp-placeholder.-height-sm
              .fp-vertical-spacing--line
                .fp-responsive-grid.-cols3.-cols2-up-md
                  .fp-responsive-grid--column(v-for='(_, index) in 6')
                    .fp-placeholder.-height-xl
        DonationFormVue(v-if='!exclusives.isLoading() && !exclusivesWithoutAuction.length' data-test='empty')
        .fp-sticky-pane.-reverse(v-if='!exclusives.isLoading() && exclusivesWithoutAuction.length')
          aside.fp-sticky-pane--aside
            DonationFormVue
          .fp-sticky-pane--content
            .fp-vertical-spacing
              .fp-vertical-spacing--line
                h2.fp-title {{ t('oneTimeDonation.featured') }}
              .fp-vertical-spacing--line
                .fp-responsive-grid.-cols3.-cols2-up-lg.-s-sm-up-sm
                  .fp-responsive-grid--column(v-for='(exclusive, index) in exclusivesWithoutAuction' :key='index' data-test='exclusives')
                    router-link.fp-link.-no-text-decoration(:to='{ name: \'exclusiveDetail\', params: { exclusiveSlug: exclusive.slug } }' :id='exclusive.slug')
                      PresentationCardVue(:exclusive='exclusive' :data-test='`exclusive-${index}`' :show-like='isAuthenticated.value()')
      .fp-vertical-spacing--line(v-if='!auctions.isLoading() && !exclusives.isLoading() && (activeAuctionsWithExclusives.length || upcomingAuctionsWithExclusives.length)' data-test='auctions')
        .fp-vertical-spacing(v-if='activeAuctionsWithExclusives.length > 0')
          .fp-vertical-spacing--line
            .fp-horizontal-spacing.-items-end
              .fp-horizontal-spacing--column.-expand
                h2.fp-title {{ t('oneTimeDonation.activeAuctions') }}
              .fp-horizontal-spacing--column
                router-link.fp-link.-secondary(:to='{name: "auctionsPage"}') {{ t('oneTimeDonation.seeAllExclusives') }}
          .fp-vertical-spacing--line(v-for='activeAuction in activeAuctionsWithExclusives' :key='activeAuction.id')
            ActiveAuctionCarouselVue(:auction='activeAuction' :exclusives='exclusivesFor(activeAuction.id)' :show-like='isAuthenticated.value()')
        .fp-vertical-spacing(v-if='upcomingAuctionsWithExclusives.length > 0')
          .fp-vertical-spacing--line
            .fp-horizontal-spacing.-items-end
              .fp-horizontal-spacing--column.-expand
                h2.fp-title {{ t('oneTimeDonation.upcomingAuctions') }}
              .fp-horizontal-spacing--column
                router-link.fp-link.-secondary(:to='{name: "auctionsPage"}') {{ t('oneTimeDonation.seeAllExclusives') }}
          .fp-vertical-spacing--line(v-for='upcomingAuction in upcomingAuctionsWithExclusives' :key='upcomingAuction.id')
            UpcomingAuctionCarouselVue(:auction='upcomingAuction' :exclusives='exclusivesFor(upcomingAuction.id)' :show-like='isAuthenticated.value()')
