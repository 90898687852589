import { Optional } from '@/common/domain/Optional';
import { BackendCaller } from '@/common/secondary/BackendCaller';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { Exclusive } from '@/fairplayer/domain/exclusive/Exclusive';
import { ExclusiveId } from '@/fairplayer/domain/exclusive/ExclusiveId';
import { ExclusiveSlug } from '@/fairplayer/domain/exclusive/ExclusiveSlug';
import { BackendExclusive, toExclusive } from '@/fairplayer/secondary/exclusive/BackendExclusive';
import { AxiosResponse } from 'axios';
import { Order } from '@/fairplayer/domain/fan/order/Order';
import { BackendOrder, toOrder } from '@/fairplayer/secondary/fan/order/BackendOrder';
import { FiatOrderToCreate } from '@/fairplayer/domain/exclusive/FiatOrderToCreate';
import { fromFiatOrderToCreate } from '@/fairplayer/secondary/exclusive/BackendFiatOrderToCreate';
import { Bid } from '@/fairplayer/domain/exclusive/Bid';
import { Authentication } from '@/common/domain/auth/Authentication';
import { BackendBid, toBid } from '@/fairplayer/secondary/exclusive/BackendBid';
import { BackendBidToCreate } from '@/fairplayer/secondary/exclusive/BackendBidToCreate';
import { BackendCheckoutSession, toCheckoutSession } from '@/fairplayer/secondary/BackendCheckoutSession';
import { CheckoutSession } from '@/fairplayer/domain/CheckoutSession';

export class BackendExclusiveRepository {
  constructor(
    private backendCaller: BackendCaller,
    private authentication: Authentication
  ) {}

  listExclusivesBySlug(slug: ClubSlug): Promise<Exclusive[]> {
    const sortByPriceDescThenNameAsc = (exclusive1: Exclusive, exclusive2: Exclusive) => {
      if (exclusive1.pricing.totalCost.value === exclusive2.pricing.totalCost.value) {
        return exclusive1.name.localeCompare(exclusive2.name);
      }

      return exclusive2.pricing.totalCost.value - exclusive1.pricing.totalCost.value;
    };

    const toExclusives = (axiosResponse: AxiosResponse<BackendExclusive[]>) => {
      const exclusives = axiosResponse.data
        .map(exclusive => toExclusive(exclusive))
        .filter(exclusive => exclusive.visible)
        .sort(sortByPriceDescThenNameAsc);

      return exclusives;
    };

    return this.backendCaller.get<BackendExclusive[]>(`api/clubs/${slug}/exclusives`).then(exclusives => toExclusives(exclusives));
  }

  async listDuePayments(clubSlug: ClubSlug): Promise<Exclusive[]> {
    const authenticatedFan = await this.authentication.authenticatedFan();
    return this.backendCaller
      .get<BackendExclusive[]>(`api/clubs/${clubSlug}/fans/${encodeURIComponent(authenticatedFan.username)}/due-payment-exclusives`)
      .then(exclusives => exclusives.data.map(toExclusive));
  }

  getExclusiveBySlug(clubSlug: ClubSlug, slug: ExclusiveSlug): Promise<Optional<Exclusive>> {
    return this.backendCaller
      .get<BackendExclusive>(`api/clubs/${clubSlug}/exclusives/${slug}`)
      .then(exclusive => Optional.of(toExclusive(exclusive.data)))
      .catch(() => Optional.empty());
  }

  async createCoinsOrder(slug: ClubSlug, exclusiveId: ExclusiveId): Promise<Order> {
    return this.backendCaller
      .post<BackendOrder>(`api/clubs/${slug}/coins-orders`, { lineItems: [{ exclusiveId }] })
      .then(({ data }) => toOrder(data));
  }

  async createFiatOrder(order: FiatOrderToCreate): Promise<CheckoutSession> {
    return this.backendCaller
      .post<BackendCheckoutSession>(`api/clubs/${order.slug}/fiat-orders`, fromFiatOrderToCreate(order))
      .then(response => toCheckoutSession(response.data));
  }

  async createBid(clubSlug: ClubSlug, exclusiveSlug: ExclusiveSlug, priceCoinsAmount: number): Promise<Bid> {
    const authenticatedFan = await this.authentication.authenticatedFan();

    const bidToCreate: BackendBidToCreate = {
      bidderUsername: authenticatedFan.username,
      priceCoinsAmount,
    };

    return this.backendCaller
      .post<BackendBid>(`api/clubs/${clubSlug}/exclusives/${exclusiveSlug}/bids`, bidToCreate)
      .then(response => toBid(response.data));
  }

  async like(clubSlug: ClubSlug, exclusiveSlug: ExclusiveSlug): Promise<void> {
    await this.backendCaller.post<void>(`api/clubs/${clubSlug}/exclusives/${exclusiveSlug}/likes`);
  }

  async unlike(clubSlug: ClubSlug, exclusiveSlug: ExclusiveSlug): Promise<void> {
    await this.backendCaller.post<void>(`api/clubs/${clubSlug}/exclusives/${exclusiveSlug}/unlikes`);
  }

  async listLikes(clubSlug: ClubSlug): Promise<ExclusiveId[]> {
    const authenticatedFan = await this.authentication.authenticatedFan();
    return this.backendCaller
      .get<string[]>(`api/clubs/${clubSlug}/fans/${encodeURIComponent(authenticatedFan.username)}/exclusive-likes`)
      .then(exclusives => exclusives.data);
  }
}
