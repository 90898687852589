import { authenticationKey } from '@/common/domain/auth/Authentication';
import { NotFoundVue } from '@/common/primary/not-found';
import { exclusiveNotFoundUi } from '@/common/primary/not-found/NotFound.ui';
import { Fan } from '@/fairplayer/domain/fan/Fan';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { walletRepositoryKey } from '@/fairplayer/domain/fan/wallet/WalletRepository';
import { PageVue } from '@/fairplayer/primary/page';
import { Loader } from '@/loader/primary/Loader';
import { computed, inject, onMounted, onUnmounted, Ref, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { ExclusiveDetailVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';
import { ExclusiveUi, fromExclusive } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { exclusiveRepositoryKey } from '@/fairplayer/domain/exclusive/ExclusiveRepository';
import { useRoute, useRouter } from 'vue-router';
import { Optional } from '@/common/domain/Optional';
import { LinkedClubBannerVue } from '@/fairplayer/primary/club/linked-club-banner';
import { HiddenExclusiveVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/hidden-exclusive';
import { RecommendedExclusivesVue } from '@/fairplayer/primary/marketplace/exclusive-page/recommended-exclusives';
import { loggerKey } from '@/common/domain/Logger';
import { bottomModalBusKey } from '@/common/domain/bottom-modal/BottomModalBus';
import { addBidModal, confirmPurchaseBottomModal, setupCardPaymentMethodModal } from '@/common/primary/bottom-modal/BottomModals';
import { Wallet } from '@/fairplayer/domain/Wallet';
import { Exclusive } from '@/fairplayer/domain/exclusive/Exclusive';
import { fromWallet, WalletUi } from '@/fairplayer/primary/wallet/Wallet.ui';
import { fromExclusiveAndFanBalance } from '@/fairplayer/domain/fan/order/OrderCost';
import { fromOrderCost } from '@/fairplayer/primary/marketplace/exclusive-page/OrderCost.ui';
import { globalWindowKey } from '@/common/domain/Window';
import { pageRedirecterKey } from '@/common/primary/PageRedirecter';
import { ExclusivePageFooterVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-page-footer';
import { AuctionStatus } from '@/fairplayer/domain/exclusive/AuctionStatus';
import { ConfirmPurchaseModalOptions } from '@/fairplayer/primary/marketplace/exclusive-page/confirm-purchase-modal/ConfirmPurchaseModalOptions';
import { AddBidModalOptions } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/add-bid-modal/AddBidModalOptions';
import { ExclusiveId } from '@/fairplayer/domain/exclusive/ExclusiveId';
import { CardPaymentMethodStatus } from '@/fairplayer/domain/fan/CardPaymentMethodStatus';
import { SetupCardPaymentMethodModalOptions } from '@/fairplayer/primary/marketplace/exclusive-page/setup-card-payment-method/SetupCardPaymentMethodModalOptions';

export default {
  name: 'ExclusivePage',

  components: {
    ExclusiveDetailVue,
    HiddenExclusiveVue,
    LinkedClubBannerVue,
    NotFoundVue,
    PageVue,
    RecommendedExclusivesVue,
    ExclusivePageFooterVue,
  },

  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const authentication = inject(authenticationKey)!;
    const bottomModalBus = inject(bottomModalBusKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const globalWindow = inject(globalWindowKey)!;
    const logger = inject(loggerKey)!;
    const pageRedirecter = inject(pageRedirecterKey)!;
    const walletRepository = inject(walletRepositoryKey)!;

    const isAuthenticated = ref(Loader.loading<boolean>());
    const wallet = ref<Wallet>();
    const walletUi = ref(Loader.loading<WalletUi | null>());
    const likes: Ref<ExclusiveId[]> = ref([]);
    const exclusive = ref(Loader.loading<Exclusive>());
    const exclusiveUi = ref(Loader.loading<ExclusiveUi>());
    const fan = ref(Loader.loading<Fan | null>());
    const displayExclusiveError = ref<boolean>(false);
    const isBuying = ref(false);
    const auctionPaymentDue = ref(false);
    const currentExclusiveSlug = computed(() => route.params.exclusiveSlug as string);
    const currentClubSlug = clubRepository.getCurrentSlug();
    const club = fromClub(clubRepository.getCurrentClub());
    const refreshExclusiveIntervalId = ref(0);

    const loadAuthenticated = async (): Promise<void> => {
      let authenticatedResponse = false;
      try {
        authenticatedResponse = await authentication.isAuthenticated();
      } catch (error: any) {
        logger.error('Failed to authenticate', error);
      } finally {
        isAuthenticated.value.loaded(authenticatedResponse);
      }
    };

    const loadFan = async (): Promise<void> => {
      fan.value.loaded(await fanRepository.getForClub(clubRepository.getCurrentClub()));
    };

    const loadWallet = async (): Promise<void> => {
      wallet.value = await walletRepository.getForClub(currentClubSlug);
      walletUi.value.loaded(fromWallet(wallet.value));
    };

    const loadUnauthenticatedWalletAndFan = () => {
      walletUi.value.loaded(null);
      fan.value.loaded(null);
    };

    const loadWalletAndFan = async () => {
      if (isAuthenticated.value.value()) {
        await Promise.all([loadWallet(), loadFan()]);
      } else {
        loadUnauthenticatedWalletAndFan();
      }
    };

    const loadOnlyFan = async () => {
      if (isAuthenticated.value.value()) {
        await loadFan();
      } else {
        fan.value.loaded(null);
      }
      walletUi.value.loaded(null);
    };

    const loadDuePayments = async () => {
      if (
        !isAuthenticated.value.value() ||
        displayExclusiveError.value ||
        exclusive.value.value().auctionLot.orElseUndefined()?.auction.status !== AuctionStatus.CLOSED
      ) {
        return;
      }

      await exclusiveRepository.listDuePayments(currentClubSlug).then(duePayments => {
        auctionPaymentDue.value = duePayments.some((e: Exclusive) => e.slug === currentExclusiveSlug.value);
        if (auctionPaymentDue.value) {
          openConfirmPurchaseBottomModal(false);
        }
      });
    };

    const loadExclusive = async (): Promise<void> => {
      try {
        const retrievedExclusive = (await exclusiveRepository.getBySlug(currentClubSlug, currentExclusiveSlug.value)).orElseThrow();
        exclusive.value.loaded(retrievedExclusive);
        autoRefreshExclusiveIfActiveAuction(retrievedExclusive);
        exclusiveUi.value.loaded(
          fromExclusive(
            retrievedExclusive,
            clubRepository.getCurrentClub(),
            fan.value.value()?.id,
            likes.value.includes(retrievedExclusive.id)
          )
        );
      } catch (error: any) {
        logger.error('Failed to retrieve exclusive', error);
        displayExclusiveError.value = true;
      }
    };

    const loadLikes = async () => {
      if (isAuthenticated.value.value()) {
        likes.value = await exclusiveRepository.listLikes(clubRepository.getCurrentSlug());
      }
    };

    const autoRefreshExclusiveIfActiveAuction = (retrievedExclusive: Exclusive) => {
      if (
        !refreshExclusiveIntervalId.value &&
        retrievedExclusive.auctionLot.filter(lot => lot.auction.status === AuctionStatus.ACTIVE).isPresent()
      ) {
        refreshExclusiveIntervalId.value = globalWindow.setInterval(loadExclusive, 2000);
      }
    };

    const purchaseBottomModalTitleKey = () => {
      if (auctionPaymentDue.value) {
        return 'titleAuction';
      }

      if (club.isFoundation) {
        return 'titleDonation';
      }

      return 'title';
    };

    const openConfirmPurchaseBottomModal = (lastPaymentCanceled: boolean) => {
      const titleKey = `marketplace.confirmPurchase.${purchaseBottomModalTitleKey()}`;
      const icon = club.coinsEnabled ? 'coins' : 'euro';
      const component = confirmPurchaseBottomModal();
      bottomModalBus.open<ConfirmPurchaseModalOptions>({
        component,
        titleKey,
        icon,
        options: {
          exclusive: exclusiveUi.value.value(),
          fan: fan.value as Loader<Fan | null>,
          wallet: walletUi.value.value()!,
          orderCost: fromOrderCost(fromExclusiveAndFanBalance(exclusive.value.value(), wallet.value)),
          lastPaymentCanceled,
          createFiatOrder,
          createCoinsOrder,
        },
      });
    };

    const openSetupCardPaymentMethodModal = () => {
      bottomModalBus.open<SetupCardPaymentMethodModalOptions>({
        component: setupCardPaymentMethodModal(),
        titleKey: 'marketplace.setupCardPaymentMethodModal.title',
        icon: 'credit-card-plus',
        options: {
          fan: fan.value as Loader<Fan | null>,
        },
      });
    };

    const addBid = async () => {
      const cardPaymentMethod = await fanRepository.getCardPaymentMethodFor(currentClubSlug);
      if (cardPaymentMethod.status === CardPaymentMethodStatus.READY) {
        openAddBidModal();
      } else {
        openSetupCardPaymentMethodModal();
      }
    };

    const buy = async (lastPaymentCanceled: boolean) => {
      const notAnAuction = exclusive.value.value().auctionLot.isEmpty();
      if (notAnAuction || auctionPaymentDue.value) {
        openConfirmPurchaseBottomModal(lastPaymentCanceled);
      } else {
        await addBid();
      }
    };

    const openAddBidModal = () => {
      const auctionLot = exclusive.value.value().auctionLot.orElseThrow();
      const currentPrice = auctionLot.currentPrice.totalCost.value;
      const emptyAuction = auctionLot.bids.length === 0;

      const minimalAmount = currentPrice + (emptyAuction ? 0 : 1);
      const amount = currentPrice + (emptyAuction ? 0 : 10);

      bottomModalBus.open<AddBidModalOptions>({
        component: addBidModal(),
        titleKey: 'addBidModal.title',
        icon: 'gavel',
        options: {
          clubSlug: currentClubSlug,
          exclusiveSlug: exclusive.value.value().slug,
          anonymous: auctionLot.auction.anonymous,
          minimalAmount,
          amount,
          added: loadExclusive,
        },
      });
    };

    const createFiatOrder = (): void => {
      const currentLocation = globalWindow.location.href.split('?')[0];
      exclusiveRepository
        .createFiatOrder({
          slug: clubRepository.getCurrentSlug(),
          exclusiveId: exclusive.value.value().id,
          currentLocation,
        })
        .then(({ url }) => pageRedirecter.navigateTo(url))
        .catch(error => {
          logger.error('Failed to create fiat order', error);
          isBuying.value = false;
        })
        .finally(() => {
          bottomModalBus.close();
        });
    };

    const createCoinsOrder = (): void => {
      exclusiveRepository
        .createCoinsOrder(clubRepository.getCurrentSlug(), exclusive.value.value().id)
        .then(() => router.push({ name: 'purchaseConfirmed' }))
        .catch(error => {
          logger.error('Failed to create coins order', error);
          isBuying.value = false;
        })
        .finally(() => {
          bottomModalBus.close();
        });
    };

    onMounted(async () => {
      await loadAuthenticated();

      if (!club.coinsEnabled) {
        await loadOnlyFan();
      } else {
        await loadWalletAndFan();
      }

      await loadLikes();

      await loadExclusive();

      await loadDuePayments();

      const paymentResult = route.query['payment-result'];
      if (paymentResult) {
        const queryParams = { ...route.query };
        delete queryParams['payment-result'];
        await router.replace({ query: queryParams });
        await buy(paymentResult === 'CANCELED');
      }
    });

    watch(
      () => [currentExclusiveSlug.value],
      () => {
        Optional.ofEmpty(currentExclusiveSlug.value).ifPresent(() => loadExclusive());
      }
    );

    onUnmounted(() => {
      if (refreshExclusiveIntervalId.value) {
        globalWindow.clearInterval(refreshExclusiveIntervalId.value);
      }
    });

    return {
      displayExclusiveError,
      auctionPaymentDue,
      createCoinsOrder,
      createFiatOrder,
      exclusiveNotFoundUi,
      addBid,
      buy,
      likes,
      club,
      fan,
      isAuthenticated,
      isBuying,
      exclusive,
      exclusiveUi,
      walletUi,
      t,
    };
  },
};
