import { defineComponent, PropType } from 'vue';
import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { ExclusivesCarouselVue } from '@/fairplayer/primary/exclusive-carousel';
import { RelativeTimeVue } from '@/common/primary/relative-time';
import { AuctionUi } from '@/fairplayer/primary/marketplace/Auction.ui';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'UpcomingAuctionCarousel',

  components: { RelativeTimeVue, ExclusivesCarouselVue },

  props: {
    exclusives: {
      type: Array as PropType<ExclusiveUi[]>,
      required: true,
    },
    auction: {
      type: Object as PropType<AuctionUi>,
      required: true,
    },
    showLike: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});
