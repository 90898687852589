import { marked, Tokens } from 'marked';
import DOMPurify from 'dompurify';
import { MarkdownUi } from '@/common/primary/Markdown.ui';

const renderer = new marked.Renderer();

export const target = (href: string) => (href.startsWith('/') ? '_self' : '_blank');

renderer.link = (link: Tokens.Link) => `<a target="${target(link.href)}" href="${link.href}">${link.text}</a>`;

marked.use({ renderer });

const getRawText = (markdown: string): string => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = markdown;
  return tempElement.textContent || '';
};

export const renderMarkdown = (input: string): MarkdownUi => {
  const md = marked(input) as string;

  return {
    html: DOMPurify.sanitize(md, { ADD_ATTR: ['target'] }),
    raw: getRawText(md),
  };
};
