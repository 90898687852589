import { ComposerTranslation } from 'vue-i18n';

export const formatRelativeTime = (date: Date, locales: Intl.LocalesArgument, t: ComposerTranslation): string => {
  if (new Date().getTime() > date.getTime()) {
    return formatPastRelativeTime(date, locales, t);
  }

  return formatFutureRelativeTime(date, locales, t);
};

const formatPastRelativeTime = (date: Date, locales: Intl.LocalesArgument, t: ComposerTranslation): string => {
  const diff = (new Date().getTime() - date.getTime()) / 1000;
  const minutes = Math.floor(diff / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const rtf = new Intl.RelativeTimeFormat(locales, { numeric: 'auto' });

  if (days > 0) {
    return rtf.format(0 - days, 'day');
  }
  if (hours > 0) {
    return rtf.format(0 - hours, 'hour');
  }
  if (minutes > 0) {
    return rtf.format(0 - minutes, 'minute');
  }

  return t('date.fewSecondsAgo');
};

const formatFutureRelativeTime = (date: Date, locales: Intl.LocalesArgument, t: ComposerTranslation): string => {
  const diff = (date.getTime() - new Date().getTime()) / 1000;
  const minutes = Math.floor(diff / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const rtf = new Intl.RelativeTimeFormat(locales, { numeric: 'auto' });

  if (days > 0) {
    return rtf.format(days, 'day');
  }
  if (hours > 0) {
    return rtf.format(hours, 'hour');
  }
  if (minutes > 0) {
    return rtf.format(minutes, 'minute');
  }

  return t('date.inAFewSeconds');
};
