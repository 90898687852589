import { MembershipToCreate } from '@/fairplayer/domain/membership/MembershipToCreate';

export interface BackendMembershipToCreate {
  successUrl: string;
  cancelUrl: string;
}

export const fromMembershipToCreate = (membership: MembershipToCreate): BackendMembershipToCreate => ({
  successUrl: `${membership.currentLocation}?payment-result=SUCCESS`,
  cancelUrl: `${membership.currentLocation}?payment-result=CANCELED`,
});
