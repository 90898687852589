.fp-vertical-spacing.-s-sm
  .fp-vertical-spacing--line
    .fp-icon-with-text
      .fp-dot
      .fp-icon-with-text--text
        .fp-icon-with-text--text-line
          h3.fp-title(data-test='name') {{ auction.name }}
        .fp-icon-with-text--text-line
          | {{ t('auctionsPage.ends') }}&nbsp;
          RelativeTimeVue(:date='auction.endDate')
  .fp-vertical-spacing--line
    ExclusivesCarouselVue(:exclusives='exclusives' :show-like='showLike')
