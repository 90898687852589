import { computed, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { PageVue } from '@/fairplayer/primary/page';
import { loggerKey } from '@/common/domain/Logger';
import { Loader } from '@/loader/primary/Loader';
import { ExclusiveUi, fromExclusive } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { exclusiveRepositoryKey } from '@/fairplayer/domain/exclusive/ExclusiveRepository';
import { PresentationCardVue } from '@/fairplayer/primary/presentation-card';
import { useRouter } from 'vue-router';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { ExclusiveId } from '@/fairplayer/domain/exclusive/ExclusiveId';
import { AuctionStatus } from '@/fairplayer/domain/exclusive/AuctionStatus';
import { AuctionUi, fromAuction } from '@/fairplayer/primary/marketplace/Auction.ui';
import { ActiveAuctionCarouselVue } from '@/fairplayer/primary/home/club-homepage/active-auction-carousel';
import { auctionRepositoryKey } from '@/fairplayer/domain/exclusive/AuctionRepository';
import { ClubLogoVue } from '@/fairplayer/primary/club-logo';
import { windowScrollerKey } from '@/common/primary/WindowScroller';
import { UpcomingAuctionCarouselVue } from '@/fairplayer/primary/home/club-homepage/upcoming-auction-carousel';
import { DonationFormVue } from '@/fairplayer/primary/donation-form';

export default {
  name: 'DonationPage',

  components: {
    PageVue,
    PresentationCardVue,
    ActiveAuctionCarouselVue,
    UpcomingAuctionCarouselVue,
    ClubLogoVue,
    DonationFormVue,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();

    const auctionRepository = inject(auctionRepositoryKey)!;
    const authentication = inject(authenticationKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const logger = inject(loggerKey)!;
    const windowScroller = inject(windowScrollerKey)!;

    const auctions = ref(Loader.loading<AuctionUi[]>());
    const exclusives = ref(Loader.loading<ExclusiveUi[]>());
    const isAuthenticated = ref(Loader.loading<boolean>());

    const exclusivesWithoutAuction = computed(() => exclusives.value.value().filter(e => e.auctionLot === undefined));
    const exclusivesWithAuction = computed(() => exclusives.value.value().filter(e => e.auctionLot !== undefined));

    const exclusivesFor = (auctionId: string) => exclusivesWithAuction.value.filter(e => e.auctionLot!.auction.id === auctionId);
    const withExclusives = (status: AuctionStatus) =>
      computed(() => auctions.value.value().filter(auction => auction.status === status && exclusivesFor(auction.id).length));

    const upcomingAuctionsWithExclusives = withExclusives(AuctionStatus.UPCOMING);
    const activeAuctionsWithExclusives = withExclusives(AuctionStatus.ACTIVE);

    const sortByPriceDescThenName = (exclu1: ExclusiveUi, exclu2: ExclusiveUi) => {
      const dif = exclu2.pricing.totalCost.value - exclu1.pricing.totalCost.value;
      if (dif === 0) {
        return exclu1.name.localeCompare(exclu2.name);
      }
      return dif;
    };

    const loadExclusives = async (): Promise<void> => {
      try {
        let fanId = undefined;
        let likes: ExclusiveId[] = [];
        if (isAuthenticated.value.value()) {
          const fan = await fanRepository.getForClub(clubRepository.getCurrentClub());
          fanId = fan.id;
          likes = await exclusiveRepository.listLikes(clubRepository.getCurrentSlug());
        }
        const exclusivesResponse = await exclusiveRepository.listBySlug(clubRepository.getCurrentSlug());
        const mappedExclusives = exclusivesResponse
          .filter(exclusive => exclusive.stock > 0)
          .map(exclusive => fromExclusive(exclusive, clubRepository.getCurrentClub(), fanId, likes.includes(exclusive.id)))
          .sort(sortByPriceDescThenName);
        exclusives.value.loaded(mappedExclusives);
      } catch (error: any) {
        logger.error('Failed to retrieve exclusives', error);
        exclusives.value.loaded([]);
      }
    };

    const retrieveAuctions = async () => {
      return auctionRepository
        .listByClub(clubRepository.getCurrentSlug())
        .then(retrievedAuctions => auctions.value.loaded(retrievedAuctions.map(fromAuction)))
        .catch(error => {
          logger.error('Failed to retrieve auctions', error);
          auctions.value.loaded([]);
        });
    };

    onMounted(async () => {
      await authentication.isAuthenticated().then(authenticated => {
        isAuthenticated.value.loaded(authenticated);
      });

      await loadExclusives();
      await retrieveAuctions();

      if (router.currentRoute.value.hash) {
        windowScroller.scrollToId(router.currentRoute.value.hash.substring(1));
      }
    });

    return {
      exclusivesFor,
      auctions,
      activeAuctionsWithExclusives,
      upcomingAuctionsWithExclusives,
      exclusivesWithAuction,
      exclusivesWithoutAuction,
      exclusives,
      isAuthenticated,
      t,
    };
  },
};
