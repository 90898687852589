import { BackendMedia, toMedia } from '@/fairplayer/secondary/exclusive/BackendMedia';
import { Post } from '@/fairplayer/domain/post/Post';
import { LocalDate } from '@/common/domain/LocalDate';
import { Optional } from '@/common/domain/Optional';

export interface BackendPost {
  id: string;
  slug: string;
  name: string;
  description: string;
  imageUrl: string;
  medias: BackendMedia[];
  date: string;
  programSlug?: string;
}

export const toPost = (backendPost: BackendPost): Post => ({
  slug: backendPost.slug,
  name: backendPost.name,
  description: backendPost.description,
  imageUrl: backendPost.imageUrl,
  medias: backendPost.medias.map(toMedia),
  date: LocalDate.fromDate(new Date(backendPost.date)),
  programSlug: Optional.ofEmpty(backendPost.programSlug),
});
