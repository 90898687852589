include ../../../../common/primary/select/select.mixin.pug

.fp-vertical-spacing
  .fp-vertical-spacing--line
    LogoWithTitleVue(:title='t(\'clubSelection.welcomeToFairplayer\')')
  .fp-vertical-spacing--line.-align-center
    span.fp-text {{t('clubSelection.chooseClub')}}
  template(v-if='!clubSummaries.loading')
    .fp-vertical-spacing--line(v-for='(club, index) in clubSummaries.value()' :key='index')
      router-link.fp-link.no-text-decoration(:to='{ name: \'clubHomepage\', params: { slug: club.slug } }' :data-test='`club-selector-${index}`')
        .fp-decorated-listing-element
          .fp-decorated-listing-element--logo
            img(:src='club.logoUrl')
          .fp-decorated-listing-element--text
            span.fp-text.-text-2xl.-text-semi-bold(v-text='club.platformName')
          .fp-decorated-listing-element--banner
            img(:src='club.bannerUrl')
